import { ContentfulCommon, ContentfulQuery } from '@truckmap/common';
import { TranslationConfig } from 'hooks/useContentful/useTranslation';
import { logWithFallback } from 'lib/error';
import { mustache } from 'lib/mustache';
import { TranslationKeys } from 'types/translationKeys';

export function getTranslation(contentfulQuery: ContentfulQuery) {
  const translationFallback = { commonCollection: { items: [] } } as ContentfulQuery;
  const { commonCollection } = contentfulQuery?.commonCollection?.items?.length
    ? contentfulQuery
    : logWithFallback(
        'commonCollection item is not queried or item keys are not found',
        translationFallback,
        'error'
      );

  const translationMap = new Map<string, ContentfulCommon>(
    commonCollection.items.map((translation) => [translation.key, translation])
  );

  return {
    translationMap,
    t(key: TranslationKeys, config?: TranslationConfig) {
      const { amount = 0, variables = Object.create(null) } = config ?? {};

      const translation =
        translationMap?.get(key) ??
        logWithFallback(
          `translation key ${key} not found or not queried`,
          { value: key, valuePlural: key },
          'warn'
        );

      return typeof amount === 'number' && (amount === 0 || amount > 1) && translation.valuePlural
        ? mustache(translation.valuePlural, variables)
        : mustache(translation.value, variables);
    }
  };
}
