import { PanelItem } from 'components/layout/Panel/PanelItem';
import { NonPrefetchLink } from 'components/NonPrefetchLink';
import { makeStyles } from 'lib/makeStyles';
import React, { memo } from 'react';

import { HeroIcon } from '../HeroIcon';
import { SmallText } from '../Typography';

export type TagButtonLinkProps = {
  label: string;
  href: string;
  active?: boolean;
};

const useStyles = makeStyles<Pick<TagButtonLinkProps, 'active'>>()({
  root: ['my-1 mr-2', 'w-max', 'group'],
  inner: ({ active = false }) => [
    'whitespace-nowrap',
    'border-2',
    'rounded-full',
    {
      'bg-secondaryBackgroundActive': active,
      'border-tmBlue': active,
      'border-secondaryBorder hover:border-tmBlue': !active,
      'transition-colors ease-out duration-5': !active
    }
  ],
  icon: [
    'bg-primaryIcon hover:bg-primaryIconHover',
    'h-4 w-4',
    'filter drop-shadow-sm hover:drop-shadow',
    'cursor-pointer'
  ]
});

export const TagButtonElement: React.FC<
  ChildrenProp & Pick<TagButtonLinkProps, 'active' | 'label'>
> = React.memo(({ children, label, active }) => {
  const styles = useStyles({ active });
  return (
    <div className={styles.root}>
      <PanelItem spacing="XS" paddingX="SM" className={styles.inner}>
        <SmallText bold active={active} enableHover="group">
          {label}
        </SmallText>
        {children}
      </PanelItem>
    </div>
  );
});

TagButtonElement.displayName = 'TagButtonElement';

export const TagButtonLinkElement: React.FC<
  ChildrenProp & Pick<TagButtonLinkProps, 'href' | 'label'>
> = React.memo(({ children, href, label }) => {
  return (
    <NonPrefetchLink
      key={`tag_button_link_${1}`}
      href={href}
      aria-label={`Add ${label}`}
      title={`Add ${label}`}>
      {children}
    </NonPrefetchLink>
  );
});

TagButtonLinkElement.displayName = 'TagButtonLinkElement';

export const TagButtonLink = memo(({ href, active = false, label = '' }: TagButtonLinkProps) => {
  const styles = useStyles({ active });

  if (!active) {
    return (
      <TagButtonLinkElement href={href} label={label}>
        <TagButtonElement active={active} label={label} />
      </TagButtonLinkElement>
    );
  }

  return (
    <TagButtonElement active={active} label={label}>
      <TagButtonLinkElement href={href} label={label}>
        <HeroIcon className={styles.icon} type="solid" name="x-circle" />
      </TagButtonLinkElement>
    </TagButtonElement>
  );
});

TagButtonLink.displayName = 'TagButtonLink';
