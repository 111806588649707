import { ContentfulDataType, ContentfulQuery } from '@truckmap/common';
import { TranslationDataTypes } from 'types/translationKeys';

export function getTranslationDataType(
  contentfulQuery: ContentfulQuery,
  type: TranslationDataTypes
) {
  const {
    dataTypeCollection: { items }
  } = contentfulQuery;

  return new Map<string, ContentfulDataType>(
    items.reduce((acc, current) => {
      if (current.type === type) {
        acc.push([current.slug, current]);
      }

      return acc;
    }, [])
  );
}
