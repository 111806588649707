'use client';
import { useMemo } from 'react';
import { TranslationDataTypes } from 'types/translationKeys';

import { getTranslation } from 'lib/contentful/getTranslation';
import { getTranslationDataType } from 'lib/contentful/getTranslationDataType';
import { useContentfulQuery } from './useContentful';

export type TranslationVariables = {
  [n: string]: number | string;
};

export type TranslationConfig = {
  amount?: number;
  variables?: TranslationVariables;
};

export function useTranslation() {
  const contentfulQuery = useContentfulQuery();
  return useMemo(() => getTranslation(contentfulQuery), [contentfulQuery]);
}

export function useTranslationDataType(type: TranslationDataTypes) {
  const contentfulQuery = useContentfulQuery();
  return useMemo(() => getTranslationDataType(contentfulQuery, type), [type, contentfulQuery]);
}
