import { AlertType } from 'components/common/Alert/Alert';
import { Button } from 'components/common/Button';
import { MetaText, SmallText } from 'components/common/Typography';
import { PanelItem } from 'components/layout/Panel/PanelItem';
import { useAlertIcon } from 'hooks/useAlertIcon';
import { makeStyles } from 'lib/makeStyles';
import React, { useCallback, useState } from 'react';

export const useStyles = makeStyles<AlertType>()({
  root: ['mx-2', 'w-full h-full', 'relative'],
  icon: (props) => [
    'shrink-0',
    'bg-white p-2 rounded-full',
    'mt-1.5',
    'drop-shadow-sm',
    'border border-neutralBorder',
    {
      'self-start mt-1/3': props.action || props.description
    }
  ],
  inlineAction: ['pr-6']
});

export const AlertContent: React.FC<AlertType> = (props) => {
  const {
    intent = 'danger',
    message,
    description,
    action,
    actionLabel,
    showSecondaryCancel,
    secondaryAction,
    secondaryActionLabel,
    inlineAction,
    inlineActionLabel,
    show
  } = props;
  const [, setVisible] = useState<boolean>(show);
  const styles = useStyles(props);
  const icon = useAlertIcon(intent);

  const buttonAction = useCallback(() => {
    if (action) {
      setVisible(false);
      action();
    }
  }, [action, setVisible]);

  const cancelAction = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const buttonSecondaryAction = useCallback(() => {
    setVisible(false);
    if (secondaryAction) {
      secondaryAction();
    }
  }, [secondaryAction, setVisible]);

  return (
    <PanelItem spacing="LG" paddingY="LG" paddingX="SM" className={styles.root}>
      <div className={styles.icon}>{icon}</div>
      <PanelItem column spacing="LG" align="start">
        <PanelItem column spacing="SM" align="start">
          <PanelItem paddingY={false} className={styles.inlineAction}>
            {typeof message === 'string' ? (
              <MetaText as="h3" bold>
                {message}
              </MetaText>
            ) : (
              message
            )}
            {inlineAction && (
              <Button borderless onClick={inlineAction}>
                <SmallText as="span" bold color="primary" enableHover>
                  {inlineActionLabel ?? 'Retry'}
                </SmallText>
              </Button>
            )}
          </PanelItem>
          {description && (
            <MetaText thin color="secondary">
              {description}
            </MetaText>
          )}
        </PanelItem>
        {action && (
          <PanelItem spacing="LG" paddingY={false}>
            <Button isSmall onClick={buttonAction}>
              {actionLabel ?? 'Confirm'}
            </Button>
            {(showSecondaryCancel && (
              <Button isSmall borderless onClick={cancelAction}>
                Cancel
              </Button>
            )) ||
              (secondaryAction && secondaryActionLabel && (
                <Button isSmall borderless onClick={buttonSecondaryAction}>
                  {secondaryActionLabel}
                </Button>
              ))}
          </PanelItem>
        )}
      </PanelItem>
    </PanelItem>
  );
};

AlertContent.displayName = 'AlertGlobal';
