'use client';
import { ContentfulUiComponent, ContentfulUiComponentItem } from '@truckmap/common';
import {
  getContentfulUiComponentFromCollection,
  getContentfulUiComponentFromPage,
  getContentfulUiComponentItems,
  getContentfulUiComponentSections
} from 'lib/contentful/contentfulHelpers';
import { createContext, useContext, useMemo } from 'react';
import { ContentfulQueryProps } from 'types/common';
import { IPage, IUIComponentFields, IUIComponentItemFields } from 'types/generated/contentful';
import { GeneratedSectionLayouts } from 'types/translationKeys';

export const ContentfulContext = createContext<ContentfulQueryProps>(null);

export const useContentfulQuery = () => useContext(ContentfulContext)?.contentfulQuery;

export const useContentfulUiComponentFromPage = (
  page: IPage,
  sectionLayoutName: GeneratedSectionLayouts
) => {
  return useMemo(
    () => getContentfulUiComponentFromPage(page, sectionLayoutName),
    [page, sectionLayoutName]
  );
};

export const useContentfulUiComponentFromQuery = (sectionLayoutName: GeneratedSectionLayouts) => {
  const { uiComponentCollection = null } = useContentfulQuery();

  return useMemo(
    () => getContentfulUiComponentFromCollection(uiComponentCollection, sectionLayoutName),
    [uiComponentCollection, sectionLayoutName]
  );
};

export const useContentfulUiComponentItems = (
  component: ContentfulUiComponent | ContentfulUiComponentItem,
  componentItemType: IUIComponentFields['type'] | IUIComponentItemFields['type']
) => {
  return useMemo(
    () => getContentfulUiComponentItems(component, componentItemType),
    [component, componentItemType]
  );
};

export const useContentfulUiComponentSections = () => {
  const { uiComponentCollection = null } = useContentfulQuery();

  return useMemo(
    () => getContentfulUiComponentSections(uiComponentCollection),
    [uiComponentCollection]
  );
};
