import { ContentfulUiComponent, ContentfulUiComponentItem } from '@truckmap/common';
import { getUiComponentSection } from 'lib/contentful/getUiComponentSection';
import { IPage, IUIComponentFields, IUIComponentItemFields } from 'types/generated/contentful';
import { GeneratedSectionLayouts } from 'types/translationKeys';

export const getContentfulUiComponentFromPage = (
  page: IPage,
  sectionLayoutName: GeneratedSectionLayouts
) => {
  return page.fields.uiComponents.find(
    ({ fields: { sectionLayout } }) => sectionLayout === sectionLayoutName
  );
};

export const getContentfulUiComponentFromCollection = (
  uiComponentCollection,
  sectionLayoutName: GeneratedSectionLayouts
) => {
  return uiComponentCollection?.items?.find(
    ({ sectionLayout }) => sectionLayout === sectionLayoutName
  );
};

export const getContentfulUiComponentItems = (
  component: ContentfulUiComponent | ContentfulUiComponentItem,
  componentItemType: IUIComponentFields['type'] | IUIComponentItemFields['type']
) => {
  return component.itemsCollection.items.filter(({ type }) => type === componentItemType);
};

export const getContentfulUiComponentSections = (uiComponentCollection) => {
  return getUiComponentSection(uiComponentCollection);
};
