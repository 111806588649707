import { makeStyles } from 'lib/makeStyles';
import React, { useMemo } from 'react';
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef
} from 'react-polymorphic-types';

export type CircleButtonOwnProps = {
  intent?: false | 'primary' | 'secondary' | 'dark' | 'neutral';
  active?: boolean;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  circle?: 'SM' | 'LG' | 'XL' | false;
  outerClassName?: string;
  disableStyle?: boolean;
} & (
  | { href: string; target?: React.HTMLAttributeAnchorTarget }
  | { href?: undefined; target?: never }
);

export const CircleButtonDefaultElement = 'button';

const useStyles = makeStyles<CircleButtonOwnProps>()({
  root: (props) => [
    'relative flex items-center justify-center',
    'rounded-full',
    'shadow-sm hover:shadow',
    'ring-0',
    'focus-default',
    {
      'w-12 h-12': props.circle === 'XL',
      'w-10 h-10': props.circle === 'LG',
      'w-8 h-8': props.circle === 'SM',
      'bg-white text-primaryIcon hover:text-primaryIconHover text-sm hover:bg-neutralBackgroundHover':
        props.intent === false && !props.disableStyle,
      'bg-tmBlue text-white hover:text-white hover:bg-tmBlueHover border border-primaryBorder':
        props.intent === 'primary',
      'bg-secondaryBackground border-secondaryBorder hover:bg-secondaryBackgroundHover text-primaryIcon hover:text-primaryIconHover':
        props.intent === 'secondary',
      'text-white bg-primaryBackgroundDark hover:bg-primaryBackgroundDarkHover':
        props.active || props.intent === 'dark',
      'bg-white hover:bg-primaryBackgroundHover border border-primaryBorder hover:border-primaryBorderHover':
        props.intent === 'neutral'
    },
    props.className
  ]
});

// TODO fix type warning
const RoundButton: PolymorphicForwardRefExoticComponent<
  CircleButtonOwnProps & ParentClassNameProp,
  typeof CircleButtonDefaultElement
> = React.forwardRef(function Button<
  T extends React.ElementType = typeof CircleButtonDefaultElement
>(
  {
    as,
    active,
    className,
    outerClassName,
    intent = false,
    circle = false,
    disableStyle = false,
    ...restProps
  }: PolymorphicPropsWithoutRef<CircleButtonOwnProps, T>,
  ref: React.ForwardedRef<Element>
) {
  const Element: React.ElementType = as || CircleButtonDefaultElement;

  const styles = useStyles({
    active,
    circle,
    className,
    intent,
    disableStyle
  });

  const innerElement = useMemo(
    () => <Element ref={ref} {...restProps} className={styles.root} />,
    [restProps, ref]
  );

  if (outerClassName) {
    return <div className={outerClassName}>{innerElement}</div>;
  }

  return innerElement;
});

RoundButton.displayName = 'RoundButton';

export { RoundButton };
